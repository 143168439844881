<template>
  <el-dialog v-if="dialogVisible" :visible.sync="dialogVisible" class="dialog-1200" title="Terms of Service" width="100%">
    <div class="content mb10">
      <p>Terms & Service (herein referred to as “the Terms”)</p>
      <span>
       When you ask for your attraction tickets to be confirmed, you are accepting that the Terms form the basis of any contract between JTRweb Limited, an authorized ticket reseller of Universal Studios Japan. The Terms shall be exclusively governed by and construed in accordance with the laws of Hong Kong.
      </span>
      <p>Prices</p>
      <span>
        Prices are inclusive of service charges and taxes. Prices displayed on the site are subject to change without notice. Once you have paid in full on a booking, we will not increase the price of your tickets.
      </span>
      <p>Deposit/payment</p>
      <span>
        There is no charge when paying by American Express, Discover and Diners, Mastercard, Visa, Click to Pay, Apple Pay, Google Pay, Microsoft Pay, China UnionPay, JCB, Alipay, WeChat Pay, FPX, Cartes Bancaires, Bancontact, EPS, giropay, iDEAL, Multibanco, Przelewy24, SOFORT, Klarna, Interac, OXXO or debit cards. Regrettably we cannot process Diners Cards. 
      </span>
      <p>Confirmation and issue of e-tickets</p>
      <span>
        Upon receipt of full payment, we will issue the corresponding e-tickets. The majority of e-tickets will be emailed within 72 hours.
      </span>
      <br>
      <p>If you amend/cancel your booking</p>
       Once you have purchased tickets, we will not amend/cancel your booking.
      <br/>
      <p>If you change your booking</p>
        
      <!-- <div class="mt15 boldFont font14 mb10">
        <p>42 days prior to departure 20%</p>
        <p>28 to 41 days 30%</p>
        <p>14 to 27 days 40%</p>
        <p>13 days of fewer 50%</p>
      </div> -->
      <span>
        If you need to make a change to your booking once you have received your tickets (for example upgrading from 1 Day Studio Pass to 2 Day Studio Pass), then this will be subject to an administrative charge of JPY200yen, PLUS the difference between the selling price of the two tickets in question. Please contact us by email or chat to arrange this.
        <br/>
      </span>
      <p>Circumstances outside of our control</p>
      <span>JTRweb Limited does not have to pay compensation or accept liability if our performance is affected by an event beyond our control. We include the following as events beyond our control – war or threat of war, riots, terrorist activity, industrial disputes, natural or nuclear disasters, fire, bad weather conditions, technical problems to transport, temporary or permanent closure of attractions or any other force majeure.</span>
      <p>Cached pages / old tickets</p>
      <span>In the rare occurrence that a user finds a cached (old) page via a search engine and purchases a ticket that no longer exists, we reserve the right to cancel this contract and make a full refund or upgrade to a current ticket. We cannot control cached pages indexed by search engines such as Google or the settings on the computer of our customers.</span>
      <p>Documentation</p>
      <span>
        JTRweb Limited accepts no responsibility for customer’s travel document and/or visa issues.
      </span>
      <p>If you have a problem with your tickets or a complaint</p>
      <span>Should you have a problem with your e-tickets at the parks, we urge you to get in touch with us immediately and while on holiday as the vast majority of problems can be resolved immediately and with very little fuss. Should you have a complaint while at any of the attractions, we urge you to discuss this at the time with the relevant authority. If you wish to complain after you have returned, please contact JTRweb Limited in writing within 28 days.</span>
      <p>Booking Conditions</p>
      <span>Tickets are sold subject to availability and to the terms and conditions imposed by our suppliers. </span>
      <span>JTRweb Limited cannot be held responsible for any action or omission of our suppliers or their servants, agents or employees. </span>
      <span>JTRweb Limited has no control over the theme parks / attractions / tours that we feature. All details and descriptions in relation thereto are for guidance only and have been provided to and copied by JTRweb Limited. </span>
      <span>JTRweb Limited is the authorized ticket reseller of Universal Studios Japan. </span>
      <span>Registered in HK No. 1978412. Registered office: ROOM 2A, 14/F, CHUN WO COMM CTR, 23-29 WING WO STREET, CENTRAL, HONG KONG</span>
      <br>
      <!-- <span>USJC Intellectual property is not allowed to use without prior approval. </span>
      <span>This site is managed by JTRweb. Any transaction made in English Sales Page is not liable to Universal Studios Japan.</span>
      <span>Products sold on English Sales Page is based on rule set by Universal Studios Japan.</span> -->
    </div>
    <el-row class="dialog-foot">
      <el-col>
        <div>
          <el-button @click="dialogVisible = false" type="primary" plain>CLOSE</el-button>
        </div>
      </el-col>
    </el-row>
    <close-icon v-if="dialogVisible" @closeDialog="dialogVisible = false" />
  </el-dialog>
</template>

<script>
import closeIcon from '@/components/close_icon.vue'

export default {
  components: { closeIcon },
  data() {
    return {
      dialogVisible: false
    }
  },
  methods: {
    init() {
      this.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .el-dialog{
    border-radius: 15px;
    max-width: 1200px;
  }
  ::v-deep .el-dialog__header{
    font-weight: bold;
    font-size: 20px;
    padding-bottom: 0;
    padding-top: 29px;
  }
  .content{
    padding: 0 20px;
    height: 492px;
    overflow: auto;
    word-break: break-word;
  }
  ::v-deep .el-dialog__body > .content > p{
    font-size: 16px;
    color: black;
  }
  ::v-deep .el-dialog__body > .content > p:not(:first-child){
    margin-top: 20px;
  }
  span{
    font-size: 14px;
    color: #797979;
  }
   ::v-deep .el-dialog__body{
   padding: 20px 0 0 0;
 }
  ::v-deep .dialog-foot{
    text-align:right;
    background-color: #eeeeee;
    padding: 15px 20px;
    // margin-top: 40px;
    border-top: 1px solid#a7a7a7;
    border-radius: 0 0 15px 15px;
  }
  ::v-deep .el-button--primary.is-plain{
    background-color: white;
    border-radius: 13px;
    font-weight: bold;
    border-color: #1073e3;
    color: #1073e3;
  }
   ::v-deep .el-dialog__headerbtn{
    display: none;
  }
    @media (max-width: 768px) {
    /deep/ .el-dialog{
      width: 100% !important;
      margin-top: 0  !important;
      margin-bottom: 0;
      height: 100vh;
      border-radius: 0;
    }
    /deep/ .content{
      height: calc(100vh - 160px);
    }
    .dialog-foot{
      border-radius: 0;
    }
  }
</style>